<template>
    <div>
        <v-row class="ma-0 pa-0">
            <v-col md="2" sm="12" cols="12">
                <Sidebar></Sidebar>
            </v-col>
            <v-col md="10" sm="12">
                <div class="headline">
                    Codified Laws
                    <v-progress-linear indeterminate v-if="loading"></v-progress-linear>
                    <v-row class="text-center">
                        <v-col cols="12" sm="6" md="5">
                            <v-breadcrumbs divider=">" :items="breadcrumbs" class="py-0" style="font-size:18px;"></v-breadcrumbs>
                        </v-col>
                        <v-col cols="12" sm="6" md="7" v-if="!loading && item">
                            <v-btn class="ma-2" color="primary" v-if="item.Previous" :to="'/Statutes/' + item.Previous">
                                <v-icon>{{arrow_back}}</v-icon> Previous
                            </v-btn>
                            <v-btn color="primary" v-if="item.Next" :to="'/Statutes/' + item.Next">
                                Next <v-icon>{{arrow_forward}}</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-container>
                        <v-layout row wrap>
                            <v-flex xs12>
                                <GoogleSearchStatute></GoogleSearchStatute>

                            </v-flex>
                        </v-layout>
                    </v-container>
                </div>
                <v-divider style="height: 10px; max-height: 10px"></v-divider>
                <v-layout row wrap v-if="Value != 'null' && item">
                    <v-spacer></v-spacer>
                    <v-btn v-if=" item && !loading" style="float:right;" color="primary" rel="noopener" :href="'/api/Statutes/' + item.Statute + '.html?all=true'">Printer Friendly</v-btn>
                </v-layout>
                <v-container>
                    <p v-if="showPendingAction" style="color: red; font-size: larger;" class="headline">
                        Pending Court Action
                    </p>
                    <p v-if="showUnconstitutional" style="color: red; font-size: larger;" class="headline">
                        Article XXX was held unconstitutional by <a href="https://ujs.sd.gov/uploads/sc/opinions/2954698e671a.pdf" style="color: inherit; text-decoration:underline;">Thom v. Barnett, 2021 S.D. 65 (2021).</a>
                    </p>
                    <div v-if="Value && Value != 'null' && item && item.Html" v-html="item.Html"></div>
                    <div v-else>
                        <v-alert v-if="!loading" dense
                                 text
                                 type="info">Not yet available.</v-alert>
                    </div>
                </v-container>
            </v-col>
        </v-row>
        <v-row class="ma-0 pa-0">
            <v-col md="2" sm="12" cols="12">
            </v-col>
            <v-col md="10" sm="12">
                <v-card style="z-index: 0;" accordion tile multiple v-if="references.length">
                    <v-card-title @click="showReferences = !showReferences" style="cursor: pointer">
                        References
                        <v-spacer></v-spacer>
                        <v-btn icon><v-icon>{{ showReferences ? mdiChevronUp: mdiChevronDown }}</v-icon></v-btn>
                    </v-card-title>
                    <v-card-text :style="referencesStyle">
                        <FilterableDataTable :headers="headers"
                                             :items="references"
                                             item-key="Statute"
                                             disable-pagination
                                             :loading="loading"
                                             loading-text="Loading... Please wait"
                                             hide-default-footer
                                             class="elevation-1">
                            <template v-slot:item.Statute="{ item }">
                                <router-link :to="'/Statutes/' + item.Statute" style="text-decoration: none;">{{item.Statute}}</router-link>
                            </template>
                            <template v-slot:item.CatchLine="{ item }">
                                <div class="hidden-md-and-up">
                                    {{item.CatchLine}}
                                </div>
                                <div class="hidden-sm-and-down">
                                    {{shortenCell(item.CatchLine)}}
                                </div>
                            </template>
                            <template v-slot:no-data>
                                <v-alert v-if="!loading" :value="true" type="info">
                                    No Results
                                </v-alert>
                            </template>
                        </FilterableDataTable>
                    </v-card-text>

                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    const GoogleSearch = () => import("@/Components/GoogleSearch.vue");
    const GoogleSearchStatute = () => import("@/Components/GoogleSearchStatute.vue");
    const Sidebar = () => import("@/Menus/Sidebar.vue");
    const FilterableDataTable = () => import("@/Components/FilterableDataTable.vue");
    import { mdiArrowLeft, mdiChevronDown, mdiChevronUp } from '@mdi/js';
    import { mdiArrowRight } from '@mdi/js';

    export default {
        name: 'CodifiedStatute',
        props: ['Value'],
        components: {
            GoogleSearch,
            GoogleSearchStatute,
            Sidebar,
            FilterableDataTable
        },
        methods: {
            LoadData()
            {
                this.loading = true;
                this.item.Html = "";

                if (this.Value != "null")
                {
                    fetch('/api/Statutes/Statute/' + this.Value,
                        {
                            method: "GET",
                            credentials: "include",
                            headers: { "Content-Type": "application/json" },
                        })
                        .then(res =>
                        {
                            if (res.redirected && res.url)
                            {
                                this.$router.push(res.url.replace(location.origin, ''));
                                return Promise.reject(302);
                            }
                            else if (res.ok)
                                return res.json();
                            else if (res.status === 404)
                                return Promise.reject(404);
                            else
                                return res.text().then(r => Promise.reject(r));
                        })
                        .then(r =>
                        {
                            if (r)
                            {
                                this.item = r;
                                this.references = [];

                                this.createBreadcrumbs("Codified Laws", "/Statutes", this.item.Statute, this.item.parents);
                                this.placeholder = "Statute or Chapter";

                                if (this.item.Statute.indexOf('2-1-1.5') > -1 || this.item.Statute.indexOf('2-1-1.6') > -1)
                                {
                                    this.showPendingAction = true;
                                    this.showUnconstitutional = false;
                                }
                                else if (this.item.Statute.indexOf('0N-30') > -1)
                                {
                                    this.showPendingAction = false;
                                    this.showUnconstitutional = true;
                                }
                                else
                                {
                                    this.showPendingAction = false;
                                    this.showUnconstitutional = false;
                                }

                                this.LoadReferents(this.item.StatuteId);
                            }
                        })
                        .catch(error =>
                        {
                            if (error == 404)
                                window.location.href = "/404?path=" + encodeURIComponent(window.location.pathname);
                            else if (error != 302)
                            {
                                let e = String(error).substring(0, 100);

                                if ((e || '').indexOf('Failed to fetch') > -1)
                                {
                                    window.location.href = "/400?path=" + encodeURIComponent(window.location.pathname) + '&error=' + encodeURIComponent(e);
                                }
                                else
                                {
                                    reportError(error);
                                    window.location.href = "/500?path=" + encodeURIComponent(window.location.pathname) + '&error=' + encodeURIComponent(e);
                                }
                            }
                        })
                        .finally(() =>
                        {
                            this.loading = false;
                        });
                }
            },
            createBreadcrumbs(route, url, child, parents)
            {
                this.readableNumber = child;

                var l = [
                    {
                        to: '/',
                        link: true,
                        text: 'Home',
                        name: 'Home'
                    },
                    {
                        to: url,
                        link: true,
                        text: route,
                        name: route,
                        exact: true,
                    }];

                let currentVal = null;

                parents.forEach((x, i) =>
                {
                    var bciL = {
                        to: '',
                        link: true,
                        text: '',
                        exact: true,
                    };

                    var val = '';

                    if (currentVal)
                    {
                        currentVal += '-' + x.Statute;
                    }
                    else
                    {
                        currentVal = x.Statute;
                    }

                    val = currentVal;
                    bciL.text = x.Statute;
                    bciL.name = val;

                    if (x.Statute != '0N')
                    {
                        switch (i)
                        {
                            case 0:
                                bciL.to = url + '/' + val;
                                break;
                            case 1:
                                bciL.to = url + '/' + val;
                                break;
                            case 2:
                                bciL.to = url + '/' + val;
                                break;
                            default:
                                bciL.to = url;
                                break;
                        }

                        if (i == parents.length - 1)
                        {
                            bciL.disabled = true;

                        }

                        l.push(bciL);
                    }
                });

                this.breadcrumbs = l;

            },
            //function currently not used.
            LoadReferences(statuteId)
            {
                fetch('/api/Statutes/' + statuteId + '/References/',
                    {
                        method: "GET",
                        credentials: "include",
                        headers: { "Content-Type": "application/json" },
                    })
                    .then(res =>
                    {
                        if (res.redirected && res.url)
                        {
                            this.$router.push(res.url.replace(location.origin, ''));
                            return Promise.reject(302);
                        }
                        else if (res.ok)
                            return res.json();
                        else if (res.status === 404)
                            return Promise.reject(404);
                        else
                            return res.text().then(r => Promise.reject(r));
                    })
                    .then(r =>
                    {
                        this.references.push(...r)
                    });
            },
            LoadReferents(statuteId)
            {
                fetch('/api/Statutes/' + statuteId + '/Referents/',
                    {
                        method: "GET",
                        credentials: "include",
                        headers: { "Content-Type": "application/json" },
                    })
                    .then(res =>
                    {
                        if (res.redirected && res.url)
                        {
                            this.$router.push(res.url.replace(location.origin, ''));
                            return Promise.reject(302);
                        }
                        else if (res.ok)
                            return res.json();
                        else if (res.status === 404)
                            return Promise.reject(404);
                        else
                            return res.text().then(r => Promise.reject(r));
                    })
                    .then(r =>
                    {
                        this.references.push(...r)
                    });
            },
            shortenCell(str)
            {
                if (str != null)
                {
                    if (str.length > 160)
                    {
                        return str.substring(0, 160);
                    } else
                    {
                        return str;
                    }
                }
            }
        },
        data: function ()
        {
            return {
                arrow_back: mdiArrowLeft,
                arrow_forward: mdiArrowRight,
                mdiChevronDown,
                mdiChevronUp,
                readableNumber: "",
                loading: true,
                showPendingAction: false,
                showUnconstitutional: false,
                item: {
                    StatuteId: null,
                    Html: null
                },
                breadcrumbs: [],
                references: [],
                showReferences: false,
                headers: [
                    {
                        text: 'Statute',
                        value: 'Statute',
                        width: '25ch'
                    },
                    {
                        text: 'Catchline',
                        value: 'CatchLine',
                        Type: 'Statute',
                        width: '*'
                    }
                ],
            };
        },
        mounted: function ()
        {
            this.LoadData();
        },
        watch: {
            Value()
            {
                this.LoadData();
            },
        },
        computed: {
            currentRoute: function ()
            {
                return this.$route.path;
            },
            JsonldBreadCrumbs: function ()
            {
                let CurrentBaseURL = window.location.origin;
                let ListItems = [];
                this.breadcrumbs.forEach((x, i) =>
                {
                    let li = {
                        "@type": "ListItem",
                        "position": i,
                        "name": x.name,
                        "item": CurrentBaseURL + x.to
                    };
                    ListItems.push(li);
                });
                return ListItems;
            },
            referencesStyle: function ()
            {
                if (this.showReferences)
                    return '';
                else
                    return 'display: none;'
            },
            Title: function ()
            {
                let title = "Codified Law ";

                if (this.item && this.item.Statute)
                {
                    title = title + this.item.Statute;
                }

                return title;
            },
            Description: function ()
            {
                if (this.item && this.item.CatchLine)
                {
                    return this.item.Catchline;
                }
                return this.Title;
            }
        },
        metaInfo()
        {
            return {
                title: this.Title,
                script: [
                    {
                        type: 'application/ld+json',
                        json: {
                            '@context': 'http://schema.org',
                            '@type': 'Legislation',
                            "@id": window.location.href,
                            'name': 'Codified Laws ' + this.readableNumber,
                            'description': this.Description,
                            'legislationType': 'Codified Laws',
                            'inLanguage': 'English'
                        }
                    }, {
                        type: 'application/ld+json',
                        json: {
                            '@context': 'http://schema.org',
                            '@type': 'BreadcrumbList',
                            "itemListElement": this.JsonldBreadCrumbs
                        }
                    }],
                meta: [
                    { vmid: 'description', name: 'description', content: this.Description },
                    // OpenGraph data (Most widely used)
                    { vmid: 'og:title', property: 'og:title', content: this.Title + ' | South Dakota Legislature' },
                    // The list of types is available here: http://ogp.me/#types
                    { vmid: 'og:type', property: 'og:type', content: 'website' },
                    // Should be the same as your canonical link, see below.
                    { vmid: 'og:url', property: 'og:url', content: window.location.href },
                    // Often the same as your meta description, but not always.
                    { vmid: 'og:description', property: 'og:description', content: this.Description },

                    // Twitter card
                    { vmid: 'twitter:card', name: 'twitter:card', content: 'summary' },
                    { vmid: 'twitter:site', name: 'twitter:site', content: window.location.href },
                    { vmid: 'twitter:title', name: 'twitter:title', content: this.Title + ' | South Dakota Legislature' },
                    { vmid: 'twitter:description', name: 'twitter:description', content: this.Description }
                ],
                link: [
                    { vmid: 'canonical', rel: 'canonical', href: window.location.href.split('?')[0] }
                ]
            };
        }
    };
</script>
<style></style>