<template>
    <v-row class="ma-0 pa-0">
        <v-col md="2" sm="12" cols="12">
            <Sidebar :SessionId="SessionId"></Sidebar>
        </v-col>
        <v-col md="10" sm="12">
            <div class="headline">
                <span v-if="Session">{{Session.YearString}}</span> Table of Contents
                <v-breadcrumbs divider=">" :items="breadcrumbs"></v-breadcrumbs>
                <v-container>
                    <v-layout row wrap>
                        <v-flex xs12>
                            <GoogleSearchStatute :SessionId="SessionId"></GoogleSearchStatute>
                        </v-flex>
                    </v-layout>
                </v-container>
            </div>
            <v-alert v-if="loading" :value="true" type="info">
                Loading Please wait
            </v-alert>
            <v-divider></v-divider>
            <FilterableDataTable :headers="headers"
                                 :items="filteredItems"
                                 :item-key="key"
                                 disable-pagination
                                 :sort-by="sortBy"
                                 :sort-desc="[false]"
                                 :loading="loading"
                                 loading-text="Loading... Please wait"
                                 hide-default-footer
                                 class="elevation-1">
                <template v-slot:item.BillNumber="{ item }">
                    <span v-if="item.BillType == null && item.BillNumber">
                        {{item.BillNumber.replace('-','&#8209;').replace(' ','&nbsp;')}}
                    </span>
                    <span v-else>
                        <router-link :to="'/Session/Bill/' + item.BillId">{{item.BillType}}&nbsp;{{item.BillNumberOnly}}</router-link>
                    </span>
                </template>
                <template v-slot:item.Chapter="{ item }">
                    <router-link :to="'/Statutes/Session_Laws/Chapter/' + item.SessionLawId" style="text-decoration: none;">
                        {{item.Chapter}}
                    </router-link>
                </template>
                <template v-slot:item.Catchline="{ item }">
                    <span v-if="$vuetify.breakpoint.mobile">
                        {{shortenCell(item.Catchline)}}
                    </span>
                    <span v-else>
                        {{item.Catchline}}
                    </span>
                </template>
                <template v-slot:no-data>
                    <v-alert v-if="!loading" :value="true" type="info">
                        No Results
                    </v-alert>
                </template>
            </FilterableDataTable>
        </v-col>
    </v-row>

</template>
<script>
    const GoogleSearchStatute = () => import("@/Components/GoogleSearchStatute.vue");
    const Sidebar = () => import("@/Menus/Sidebar.vue");
    const FilterableDataTable = () => import("@/Components/FilterableDataTable.vue");

    export default {
        name: 'Statutes',
        props: ['SessionId'],
        components: {
            GoogleSearchStatute,
            Sidebar,
            FilterableDataTable
        },
        methods: {
            LoadData()
            {
                this.loading = true;

                if (this.SessionId)
                {
                    fetch('/api/Sessions/' + this.SessionId,
                        {
                            method: "GET",
                            credentials: "include",
                            headers: { "Content-Type": "application/json" },
                        })
                        .then(res =>
                        {
                            if (res.ok)
                                return res.json();
                            else if (res.status === 404)
                                window.location.href = "/404?path=" + encodeURIComponent(window.location.pathname);
                            else
                                return res.text().then(r => Promise.reject(r));
                        })
                        .then(response =>
                        {
                            this.Session = EncodeDatetimes(response);

                            this.createBreadcrumbs('Session Laws (' + this.Session.Year + ')', '/Statutes/Session_Laws');
                        })
                        .catch(error =>
                        {
                            if (error == 404)
                                window.location.href = "/404?path=" + encodeURIComponent(window.location.pathname);
                            else if (error != 302)
                            {
                                let e = String(error).substring(0, 100);

                                if ((e || '').indexOf('Failed to fetch') > -1)
                                {
                                    window.location.href = "/400?path=" + encodeURIComponent(window.location.pathname) + '&error=' + encodeURIComponent(e);
                                }
                                else
                                {
                                    reportError(error);
                                    window.location.href = "/500?path=" + encodeURIComponent(window.location.pathname) + '&error=' + encodeURIComponent(e);
                                }
                            }
                        });

                    fetch('/api/SessionLaws/' + this.SessionId,
                        {
                            method: "GET",
                            credentials: "include",
                            headers: { "Content-Type": "application/json" },
                        })
                        .then(res =>
                        {
                            if (res.ok)
                                return res.json();
                            else
                                return res.text().then(r => Promise.reject(r));
                        })
                        .then(response =>
                        {
                            this.items = EncodeDatetimes(response);
                        })
                        .catch(error =>
                        {
                            if (error == 404)
                                window.location.href = "/404?path=" + encodeURIComponent(window.location.pathname);
                            else if (error != 302)
                            {
                                let e = String(error).substring(0, 100);

                                if ((e || '').indexOf('Failed to fetch') > -1)
                                {
                                    window.location.href = "/400?path=" + encodeURIComponent(window.location.pathname) + '&error=' + encodeURIComponent(e);
                                }
                                else
                                {
                                    reportError(error);
                                    window.location.href = "/500?path=" + encodeURIComponent(window.location.pathname) + '&error=' + encodeURIComponent(e);
                                }
                            }
                        })
                        .finally(() =>
                        {
                            this.loading = false;
                        });
                }

            },

            createBreadcrumbs(route, url)
            {
                this.breadcrumbs = [{
                    to: '/',
                    link: true,
                    text: 'Home',
                }];

                var bci2 = {
                    to: '',
                    link: true,
                    text: '',
                };
                bci2.to = url;
                bci2.text = route;
                bci2.disabled = true;

                this.breadcrumbs.push(bci2);
            },
            shortenCell(str)
            {
                if (str != null)
                {
                    if (str.length > 110)
                    {
                        return str.substring(0, 160);
                    } else
                    {
                        return str;
                    }
                }
            }

        },
        data: function ()
        {
            return {
                StatuesEffectiveDate: 'July 1, 2024',
                loading: false,
                error: null,
                sortBy: ['Chapter'],
                quickFind: null,
                placeholder: 'Article, Chapter, or Section',
                breadcrumbs: [],
                items: [],
                Session: {},
                key: 'SessionLawId',
                headers: [
                    {
                        text: 'Chapter',
                        value: 'Chapter',
                        Type: 'Session_Laws',
                        width: '15ch'
                    },
                    {
                        text: 'Bill',
                        value: 'BillNumber',
                        Type: 'Session_Laws',
                        width: '15ch'
                    },
                    {
                        text: 'Catchline',
                        value: 'Catchline',
                        Type: 'Session_Laws',
                        width: '*'
                    },
                ],
                rulefilter: "Active",
            };
        },
        mounted: function ()
        {
            this.LoadData();
        },
        watch: {
            currentRoute()
            {
                this.items = [];
                this.LoadData();
            },
            SessionId()
            {
                this.LoadData();
            },
        },
        computed: {
            currentRoute: function ()
            {
                return this.$route.path;
            },
            filteredItems: function ()
            {
                if (this.items && this.items.length > 0)
                {
                    return this.items;
                }
                else
                {
                    return [];
                }
            },
            Title: function ()
            {
                let title = '';

                if (this.Session && this.Session.Year)
                {
                    title = this.Session.Year + " Session Laws";
                }
                else
                {
                    title = "Session Laws";
                }

                return title;
            },
            Description: function ()
            {
                return this.Title;
            }
        },
        metaInfo()
        {
            return {
                title: this.Title,
                meta: [
                    { vmid: 'description', name: 'description', content: this.Description },
                    // OpenGraph data (Most widely used)
                    { vmid: 'og:title', property: 'og:title', content: this.Title + ' | South Dakota Legislature' },
                    // The list of types is available here: http://ogp.me/#types
                    { vmid: 'og:type', property: 'og:type', content: 'website' },
                    // Should be the same as your canonical link, see below.
                    { vmid: 'og:url', property: 'og:url', content: window.location.href },
                    // Often the same as your meta description, but not always.
                    { vmid: 'og:description', property: 'og:description', content: this.Description },

                    // Twitter card
                    { vmid: 'twitter:card', name: 'twitter:card', content: 'summary' },
                    { vmid: 'twitter:site', name: 'twitter:site', content: window.location.href },
                    { vmid: 'twitter:title', name: 'twitter:title', content: this.Title + ' | South Dakota Legislature' },
                    { vmid: 'twitter:description', name: 'twitter:description', content: this.Description }
                ],
                link: [
                    { vmid: 'canonical', rel: 'canonical', href: window.location.href.split('?')[0].replace('rules', 'Rules').replace('administrative', 'Administrative').replace('statutes', 'Statutes').replace('constitution', 'Constitution').replace('codified_laws', 'Codified_Laws').replace('session_laws', 'Session_Laws') }
                ]
            };
        }
    };
</script>
<style></style>