<template>
    <v-container>
        <v-layout row wrap>
            <v-flex xs12>
                <v-card height="400" color="transparent" flat>
                    <div class="display-3 mt-5">Server Error.</div>
                    <div class="grey--text lighten-5">
                        Please wait a few minutes and try again.
                    </div>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>
<script>
    export default {
        name: 'Error404',
        props: {
        },
        methods: {

        },
        data: function ()
        {
            return {
            };
        },
        watch: {
        },
        mounted()
        {

        },
        computed: {
            Title: function ()
            {
                let title = 'Server Error';
                return title;
            },
            Description: function ()
            {
                return this.Title;
            }
        },
        metaInfo()
        {
            return {
                title: this.Title,
                meta: [
                    { vmid: 'description', name: 'description', content: this.Description },
                    // OpenGraph data (Most widely used)
                    { vmid: 'og:title', property: 'og:title', content: this.Title + ' | South Dakota Legislature' },
                    // The list of types is available here: http://ogp.me/#types
                    { vmid: 'og:type', property: 'og:type', content: 'website' },
                    // Should be the same as your canonical link, see below.
                    { vmid: 'og:url', property: 'og:url', content: window.location.href },
                    // Often the same as your meta description, but not always.
                    { vmid: 'og:description', property: 'og:description', content: this.Description },

                    // Twitter card
                    { vmid: 'twitter:card', name: 'twitter:card', content: 'summary' },
                    { vmid: 'twitter:site', name: 'twitter:site', content: window.location.href },
                    { vmid: 'twitter:title', name: 'twitter:title', content: this.Title + ' | South Dakota Legislature' },
                    { vmid: 'twitter:description', name: 'twitter:description', content: this.Description },
                    { name: "robots", content: "noindex" },
                ],
                link: [
                    { vmid: 'canonical', rel: 'canonical', href: window.location.protocol + '//' + window.location.host + '/500' }
                ]
            };
        }
    };
</script>
<style></style>