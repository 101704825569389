<template>
    <v-row class="ma-0 pa-0">
        <v-col md="2" sm="12" cols="12">
            <Sidebar></Sidebar>
        </v-col>
        <v-col md="10" sm="12">
            <v-card flat v-if="ServiceMember">
                <v-card-title v-if="ServiceMember.LastName">
                    <span v-if="ServiceMember.FirstName">{{ServiceMember.FirstName}}&nbsp;</span><span v-if="ServiceMember.MiddleName">{{ServiceMember.MiddleName}}&nbsp;</span><span v-if="ServiceMember.LastName">{{ServiceMember.LastName}}</span>
                </v-card-title>
                <v-card-text>
                    <v-flex md3 v-if="ServiceMember.Sex">
                        <b>Gender: </b>{{ServiceMember.Sex}}
                    </v-flex>
                    <v-flex md3 v-if="ServiceMember.Birthdate">
                        <b>Birthday: </b>{{ServiceMember.Birthdate}}
                    </v-flex>
                    <v-flex md3 v-if="ServiceMember.Deathdate">
                        <b>Date of Death: </b>{{ServiceMember.Deathdate}}
                    </v-flex>
                    <v-flex md3 v-if="ServiceMember.Remarks">
                        <b>Remarks: </b>{{ServiceMember.Remarks}}
                    </v-flex>
                    <br />
                    <div class="headline">Years of Service</div>
                    <div v-if="ServiceMember.TotalYears">Total Years of Service: <v-chip>{{ServiceMember.TotalYears}}</v-chip></div>
                    <FilterableDataTable v-if="ServiceMembers" :headers="YearsHeaders"
                                         :items="ServiceMembers"
                                         :search="search"
                                         :loading="loading"
                                         hide-default-footer
                                         disable-pagination
                                         componentName="ServiceMembersDatagrid"
                                         :editHeaders="false"
                                         item-key="MemberServiceID"
                                         class="elevation-1">

                        <v-alert slot="no-results" :value="true" type="info">
                            Your search for "{{ search }}" found no results.
                        </v-alert>
                    </FilterableDataTable>
                    <br />
                    <div class="headline">Offices</div>
                    <FilterableDataTable v-if="MemberOffices" :headers="OfficeHeaders"
                                         :items="MemberOffices"
                                         :search="search"
                                         :loading="loading"
                                         hide-default-footer
                                         disable-pagination
                                         componentName="MemberOffices"
                                         :editHeaders="false"
                                         item-key="MemberOfficeID"
                                         class="elevation-1">
                        <v-alert slot="no-results" :value="true" type="info">
                            Your search for "{{ search }}" found no results.
                        </v-alert>
                    </FilterableDataTable>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>
<script>
    const FilterableDataTable = () => import("@/Components/FilterableDataTable.vue");
    const Sidebar = () => import("@/Menus/Sidebar.vue");

    export default {
        name: 'HistoricalProfile',
        props: ['MemberId'],
        components: {
            FilterableDataTable,
            Sidebar
        },
        methods: {
            switchTab(item)
            {
                this.filterValue = item;
            },
            LoadData()
            {
                fetch('/api/Historical/Member/' + this.MemberId,
                    {
                        method: "GET",
                        credentials: "include",
                        headers: { "Content-Type": "application/json" },
                    })
                    .then(res =>
                    {
                        if (res.ok)
                        {
                            return res.json();
                        }
                        else if (res.status === 404)
                        {
                            this.$router.push('/Legislators/Historical');///Legislators/Profile/1695/Detail
                            return null;
                        }
                        throw new Error(res.statusText);
                    })
                    .then(response =>
                    {
                        this.loading = false;

                        if (response)
                        {
                            let Encoded = EncodeDatetimes(response);
                            if (Encoded)
                            {
                                this.ServiceMember = Encoded;
                                if (this.ServiceMember && this.ServiceMember.MemberServices)
                                {
                                    this.ServiceMembers = this.ServiceMember.MemberServices;
                                }
                                if (this.ServiceMember && this.ServiceMember.MemberOffices)
                                {
                                    this.MemberOffices = this.ServiceMember.MemberOffices;
                                }
                            }
                        }

                        if (this.ServiceMember === undefined)
                        {
                            this.ServiceMember = {};
                        }
                    }).catch(error =>
                    {
                        this.loading = false;
                        throw new Error(error);
                    });
            },
        },
        data: function ()
        {
            return {
                search: null,
                loading: true,
                filterValue: 'Detail',
                tabs: ['Detail', 'Committees', 'Bills', 'Hearings'],
                ServiceMember: {},
                ServiceMembers: [],
                MemberOffices: [],
                YearsHeaders: [
                    {
                        text: 'Party',
                        value: 'Party',
                        align: 'left',
                        width: '1%',
                        sortable: true,
                        visible: true,
                        filterable: true,
                    },
                    {
                        text: 'Address',
                        value: 'Address',
                        align: 'left',
                        width: '1%',
                        sortable: true,
                        visible: true,
                        filterable: true,
                    },
                    {
                        text: 'County',
                        value: 'County',
                        align: 'left',
                        width: '1%',
                        sortable: true,
                        visible: true,
                        filterable: true,
                    },
                    {
                        text: 'Body',
                        value: 'BodyLong',
                        align: 'left',
                        width: '1%',
                        sortable: true,
                        visible: true,
                        filterable: true,
                    },
                    {
                        text: 'Start Year',
                        value: 'StartYear',
                        align: 'left',
                        width: '1%',
                        sortable: true,
                        visible: true,
                        filterable: true,
                    },
                    {
                        text: 'End Year',
                        value: 'EndYear',
                        align: 'left',
                        width: '1%',
                        sortable: true,
                        visible: true,
                        filterable: true,
                    },
                ],
                OfficeHeaders: [
                    {
                        text: 'Party',
                        value: 'Party',
                        align: 'left',
                        width: '1%',
                        sortable: true,
                        visible: true,
                        filterable: true,
                    },
                    {
                        text: 'Position',
                        value: 'Position',
                        align: 'left',
                        width: '1%',
                        sortable: true,
                        visible: true,
                        filterable: true,
                    },
                    {
                        text: 'Title',
                        value: 'Title',
                        align: 'left',
                        width: '1%',
                        sortable: true,
                        visible: true,
                        filterable: true,
                    },
                    {
                        text: 'Address',
                        value: 'Address',
                        align: 'left',
                        width: '1%',
                        sortable: true,
                        visible: true,
                        filterable: true,
                    },
                    {
                        text: 'Year',
                        value: 'Year',
                        align: 'left',
                        width: '1%',
                        sortable: true,
                        visible: true,
                        filterable: true,
                    },
                    {
                        text: 'Comment',
                        value: 'Comment',
                        align: 'left',
                        width: '1%',
                        sortable: true,
                        visible: true,
                        filterable: true,
                    },
                ],
            };
        },
        mounted: function ()
        {
            this.LoadData();
        },
        watch: {
            MemberId: {
                handler()
                {
                    this.LoadData();
                },
            },
        },
        computed: {
            Title: function ()
            {
                let title = 'Loading...';

                if (this.ServiceMember.LastName)
                {
                    title = this.ServiceMember.FirstName;

                    if (this.ServiceMember.MiddleName)
                        title += ' ' + this.ServiceMember.MiddleName;

                    title += ' ' + this.ServiceMember.LastName;
                }

                return title;
            },
            FirstName: function ()
            {
                return this.ServiceMember.FirstName;
            },
            LastName: function ()
            {
                return this.ServiceMember.LastName;
            },
        },
        metaInfo()
        {
            //https://stackoverflow.com/questions/286141/remove-blank-attributes-from-an-object-in-javascript
            let removeEmpty = function (obj)
            {
                const newObj = {};

                Object.entries(obj).forEach(([k, v]) =>
                {
                    if (Array.isArray(v))
                    {
                        newObj[k] = new Array();

                        v.forEach(x =>
                        {
                            if (x === Object(x))
                            {
                                newObj[k].push(removeEmpty(x));
                            }
                            else if (x != null)
                            {
                                newObj[k].push(x);
                            }
                        });
                    }
                    else if (v === Object(v))
                    {
                        newObj[k] = removeEmpty(v);
                    } else if (v != null)
                    {
                        newObj[k] = obj[k];
                    }
                });
                return newObj;
            };

            let person = {
                title: this.Title,
                script: [
                    {
                        type: 'application/ld+json',
                        json: {
                            '@context': 'http://schema.org',
                            '@type': 'Person',
                            'name': (this.Member || {}).FirstLastName,
                            'image': (this.Member || {}).PictureSmall
                        }
                    }],
                meta: [
                    { vmid: 'description', name: 'description', content: this.Title },
                    // OpenGraph data (Most widely used)
                    { vmid: 'og:title', property: 'og:title', content: this.Title + ' | South Dakota Legislature' },
                    // The list of types is available here: http://ogp.me/#types
                    { vmid: 'og:type', property: 'og:type', content: 'profile' },
                    // Should be the same as your canonical link, see below.
                    { vmid: 'og:url', property: 'og:url', content: window.location.href.split('?')[0] },
                    // Often the same as your meta description, but not always.
                    //{ vmid: 'og:image', property: 'og:image', content: (this.Member || {}).PictureSmall },
                    //{ vmid: 'og:image:width', property: 'og:image:width', content: '100' },
                    //{ vmid: 'og:image:height', property: 'og:image:height', content: '130' },
                    { vmid: 'profile:first_name', property: 'profile:first_name', content: this.FirstName },
                    { vmid: 'profile:last_name', property: 'profile:last_name', content: this.LastName },

                    // Twitter card
                    { vmid: 'twitter:card', name: 'twitter:card', content: 'summary' },
                    { vmid: 'twitter:site', name: 'twitter:site', content: window.location.href.split('?')[0] },
                    { vmid: 'twitter:title', name: 'twitter:title', content: this.Title + ' | South Dakota Legislature' },
                    { vmid: 'twitter:description', name: 'twitter:description', content: this.Title },
                    //{ vmid: 'twitter:image', name: 'twitter:image', content: (this.Member || {}).PictureSmall },
                    //{ vmid: 'twitter:image:alt', name: 'twitter:image:alt', content: 'A Profile Picture of ' + (this.Member || {}).FirstLastName }
                ],
                link: [
                    { vmid: 'canonical', rel: 'canonical', href: window.location.href.split('?')[0] }
                ]
            };

            person = removeEmpty(person);

            return person;
        }
    };
</script>
<style></style>