<template>
    <v-row class="ma-0 pa-0">
        <v-col md="2" sm="12" cols="12">
            <Sidebar></Sidebar>
        </v-col>
        <v-col md="10" sm="12">
            <div class="headline">
                Codified Laws
                <p style="color: red; font-size: larger;">
                    Effective {{StatuesEffectiveDate}}.
                </p>
                <v-breadcrumbs divider=">" :items="breadcrumbs"></v-breadcrumbs>
                <v-container>
                    <v-layout row wrap>
                        <v-flex xs12>
                            <GoogleSearchStatute></GoogleSearchStatute>
                        </v-flex>
                    </v-layout>
                </v-container>
            </div>
            <v-divider></v-divider>
            <v-alert v-if="loading" :value="true" type="info">
                Loading Please wait
            </v-alert>
            <FilterableDataTable :headers="headers"
                                 :items="items"
                                 item-key="StatuteId"
                                 disable-pagination
                                 :loading="loading"
                                 loading-text="Loading... Please wait"
                                 hide-default-footer
                                 class="elevation-1">
                <template v-slot:item.Title="{ item }">
                    <router-link :to="'/Statutes/' + item.Statute" style="text-decoration: none;">{{item.Title}}{{item.TitleLetter}}</router-link>
                </template>
                <template v-slot:item.CatchLine="{ item }">
                    <div class="hidden-md-and-up">
                        {{item.CatchLine}}
                    </div>
                    <div class="hidden-sm-and-down">
                        {{shortenCell(item.CatchLine)}}
                    </div>
                </template>
                <template v-slot:no-data>
                    <v-alert v-if="!loading" :value="true" type="info">
                        No Results
                    </v-alert>
                </template>
            </FilterableDataTable>
        </v-col>
    </v-row>

</template>
<script>
    const GoogleSearchStatute = () => import("@/Components/GoogleSearchStatute.vue");
    const Sidebar = () => import("@/Menus/Sidebar.vue");
    const FilterableDataTable = () => import("@/Components/FilterableDataTable.vue");

    export default {
        name: 'CodifiedStatutes',
        components: {
            GoogleSearchStatute,
            Sidebar,
            FilterableDataTable
        },
        methods: {
            LoadData()
            {
                this.loading = true;
                fetch('/api/Statutes/LastStatuesEffectiveDate',
                    {
                        method: "GET",
                        credentials: "include",
                        headers: { "Content-Type": "application/json" },
                    })
                    .then(res =>
                    {
                        this.loading = false;

                        if (res.ok)
                            return res.json();
                        else
                            return res.text().then(r => Promise.reject(r));
                    })
                    .then(response =>
                    {
                        let realdateTime = EncodeDatetimes(response);

                        if (realdateTime)
                        {
                            this.StatuesEffectiveDate = realdateTime.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
                        }
                    })
                    .catch(error =>
                    {
                        if (error == 404)
                            window.location.href = "/404?path=" + encodeURIComponent(window.location.pathname);
                        else if (error != 302)
                        {
                            let e = String(error).substring(0, 100);

                            if ((e || '').indexOf('Failed to fetch') > -1)
                            {
                                window.location.href = "/400?path=" + encodeURIComponent(window.location.pathname) + '&error=' + encodeURIComponent(e);
                            }
                            else
                            {
                                reportError(error);
                                window.location.href = "/500?path=" + encodeURIComponent(window.location.pathname) + '&error=' + encodeURIComponent(e);
                            }
                        }
                    });

                this.error = null;

                this.createBreadcrumbs('Codified Laws', '/Statutes');

                fetch('/api/Statutes/Title',
                    {
                        method: "GET",
                        credentials: "include",
                        headers: { "Content-Type": "application/json" },
                    })
                    .then(res =>
                    {
                        if (res.ok)
                            return res.json();
                        else
                            return res.text().then(r => Promise.reject(r));
                    })
                    .then(response =>
                    {
                        this.items = EncodeDatetimes(response);
                    })
                    .catch(error =>
                    {
                        if (error == 404)
                            window.location.href = "/404?path=" + encodeURIComponent(window.location.pathname);
                        else if (error != 302)
                        {
                            let e = String(error).substring(0, 100);

                            if ((e || '').indexOf('Failed to fetch') > -1)
                            {
                                window.location.href = "/400?path=" + encodeURIComponent(window.location.pathname) + '&error=' + encodeURIComponent(e);
                            }
                            else
                            {
                                reportError(error);
                                window.location.href = "/500?path=" + encodeURIComponent(window.location.pathname) + '&error=' + encodeURIComponent(e);
                            }
                        }
                    })
                    .finally(() =>
                    {
                        this.loading = false;
                    });
            },
            createBreadcrumbs(route, url)
            {
                this.breadcrumbs = [{
                    to: '/',
                    link: true,
                    text: 'Home',
                }];

                var bci2 = {
                    to: '',
                    link: true,
                    text: '',
                };
                bci2.to = url;
                bci2.text = route;
                bci2.disabled = true;

                this.breadcrumbs.push(bci2);
            },
            shortenCell(str)
            {
                if (str != null)
                {
                    if (str.length > 160)
                    {
                        return str.substring(0, 160);
                    } else
                    {
                        return str;
                    }
                }
            }

        },
        data: function ()
        {
            return {
                StatuesEffectiveDate: 'July 1, 2024',
                loading: false,
                breadcrumbs: [],
                items: [],
                headers: [
                    {
                        text: 'Title',
                        value: 'Title',
                        Type: 'Codified_Law',
                        width: '15ch'
                    },
                    {
                        text: 'Catchline',
                        value: 'CatchLine',
                        Type: 'Statute',
                        width: '*'
                    }
                ],
            };
        },
        mounted: function ()
        {
            this.LoadData();
        },
        watch: {
        },
        computed: {
            Title: function ()
            {
                return "Codified Laws";
            },
            Description: function ()
            {
                return this.Title;
            }
        },
        metaInfo()
        {
            return {
                title: this.Title,
                meta: [
                    { vmid: 'description', name: 'description', content: this.Description },
                    // OpenGraph data (Most widely used)
                    { vmid: 'og:title', property: 'og:title', content: this.Title + ' | South Dakota Legislature' },
                    // The list of types is available here: http://ogp.me/#types
                    { vmid: 'og:type', property: 'og:type', content: 'website' },
                    // Should be the same as your canonical link, see below.
                    { vmid: 'og:url', property: 'og:url', content: window.location.href },
                    // Often the same as your meta description, but not always.
                    { vmid: 'og:description', property: 'og:description', content: this.Description },

                    // Twitter card
                    { vmid: 'twitter:card', name: 'twitter:card', content: 'summary' },
                    { vmid: 'twitter:site', name: 'twitter:site', content: window.location.href },
                    { vmid: 'twitter:title', name: 'twitter:title', content: this.Title + ' | South Dakota Legislature' },
                    { vmid: 'twitter:description', name: 'twitter:description', content: this.Description }
                ],
                link: [
                    { vmid: 'canonical', rel: 'canonical', href: window.location.href.split('?')[0].replace('rules', 'Rules').replace('administrative', 'Administrative').replace('statutes', 'Statutes').replace('constitution', 'Constitution').replace('codified_laws', 'Codified_Laws').replace('session_laws', 'Session_Laws') }
                ]
            };
        }
    };
</script>
<style></style>