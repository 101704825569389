<template>
    <v-row class="ma-0 pa-0">
        <v-col md="2" sm="12" cols="12">
            <Sidebar></Sidebar>
        </v-col>
        <v-col md="10" sm="12">
            <div class="headline">
                Constitution
                <v-progress-linear indeterminate v-if="loading"></v-progress-linear>
                <v-row class="text-center">
                    <v-col cols="12" sm="6" md="5">
                        <v-breadcrumbs divider=">" :items="breadcrumbs" class="py-0" style="font-size:18px;"></v-breadcrumbs>
                    </v-col>
                    <v-col cols="12" sm="6" md="7" v-if="!loading && item">
                        <v-btn class="ma-2" color="primary" v-if="item.Previous && currentRoute.includes('Constitution')" :to="'/Constitution/' + item.Previous">
                            <v-icon>{{arrow_back}}</v-icon> Previous
                        </v-btn>
                        <v-btn color="primary" v-if="item.Next && currentRoute.includes('Constitution')" :to="'/Constitution/' + item.Next">
                            Next <v-icon>{{arrow_forward}}</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-container>
                    <v-layout row wrap>
                        <v-flex xs12>
                            <GoogleSearchStatute></GoogleSearchStatute>
                        </v-flex>
                    </v-layout>
                </v-container>
            </div>
            <v-divider style="height: 10px; max-height: 10px" v-if="!hideSessionLawSearch"></v-divider>
            <v-layout row wrap v-if="Value != 'null' && item">
                <v-spacer></v-spacer>
                <v-btn v-if="item && !loading" style="float:right;" text rel="noopener" :href="'/api/Statutes/' + item.Statute + '.html?all=true'">Printer Friendly</v-btn>
            </v-layout>
            <v-container>
                <p v-if="showPendingAction" style="color: red; font-size: larger;" class="headline">
                    Pending Court Action
                </p>
                <p v-if="showUnconstitutional" style="color: red; font-size: larger;" class="headline">
                    Article XXX was held unconstitutional by <a href="https://ujs.sd.gov/uploads/sc/opinions/2954698e671a.pdf" style="color: inherit; text-decoration:underline;">Thom v. Barnett, 2021 S.D. 65 (2021).</a>
                </p>
                <div v-if="Value && Value != 'null' && item && item.Html" v-html="item.Html"></div>
                <div v-else>
                    <v-alert v-if="!loading" dense
                             text
                             type="info">Not yet available.</v-alert>
                </div>
            </v-container>
        </v-col>
    </v-row>
</template>

<script>
    const GoogleSearch = () => import("@/Components/GoogleSearch.vue");
    const GoogleSearchStatute = () => import("@/Components/GoogleSearchStatute.vue");
    const Sidebar = () => import("@/Menus/Sidebar.vue");

    import { mdiArrowLeft } from '@mdi/js';
    import { mdiArrowRight } from '@mdi/js';

    export default {
        name: 'ConstitutionalArticle',
        props: ['Value'],
        components: {
            GoogleSearch,
            GoogleSearchStatute,
            Sidebar
        },
        methods: {
            LoadData()
            {
                this.loading = true;
                if (this.item)
                {
                    this.item.Html = "";
                }
                else
                {
                    this.item = {
                        Html: "",
                        StatuteId: null
                    };
                }

                if (this.Value != "null")
                {
                    this.error = null;

                    if (this.Value.includes("0N-"))
                    {
                        this.$router.push(location.pathname.replace('0N-', ''));
                        return null;
                    }

                    fetch('/api/Statutes/Constitution/' + this.Value,
                        {
                            method: "GET",
                            credentials: "include",
                            headers: { "Content-Type": "application/json" },
                        })
                        .then(res =>
                        {
                            this.loading = false;

                            if (res.redirected && res.url)
                            {
                                this.$router.replace(res.url.replace(location.origin, ''));
                                return Promise.reject(302);
                            }
                            else if (res.ok)
                                return res.json();
                            else if (res.status === 404)
                                return Promise.reject(404);
                            else
                                return res.text().then(r => Promise.reject(r));
                        })
                        .then(response =>
                        {
                            this.item = response;

                            if (this.item)
                            {
                                this.createBreadcrumbs("Constitution", "/Constitution", this.item.Statute, this.item.parents);
                                this.placeholder = "Section or Article";
                            }
                        })
                        .catch(error =>
                        {
                            if (error == 404)
                                window.location.href = "/404?path=" + encodeURIComponent(window.location.pathname);
                            else if (error != 302)
                            {
                                let e = String(error).substring(0, 100);

                                if ((e || '').indexOf('Failed to fetch') > -1)
                                {
                                    window.location.href = "/400?path=" + encodeURIComponent(window.location.pathname) + '&error=' + encodeURIComponent(e);
                                }
                                else
                                {
                                    reportError(error);
                                    window.location.href = "/500?path=" + encodeURIComponent(window.location.pathname) + '&error=' + encodeURIComponent(e);
                                }
                            }
                        });

                }
            },
            createBreadcrumbs(route, url, child, parents)
            {
                this.readableNumber = child;

                var l = [
                    {
                        to: '/',
                        link: true,
                        text: 'Home',
                        name: 'Home'
                    },
                    {
                        to: url,
                        link: true,
                        text: route,
                        exact: true,
                        name: route
                    }];

                let currentVal = null;

                parents.forEach((x, i) =>
                {
                    var bciL = {
                        to: '',
                        link: true,
                        text: '',
                        exact: true,
                        name: ''
                    };
                    var val = '';

                    if (currentVal)
                    {
                        currentVal += '-' + x.Statute;
                    }
                    else
                    {
                        currentVal = x.Statute;
                    }

                    val = currentVal;
                    bciL.text = x.Statute;
                    bciL.name = currentVal;

                    if (x.Statute != '0N')
                    {
                        switch (i)
                        {
                            case 0:
                                bciL.to = url + '/' + val;
                                break;
                            case 1:
                                bciL.to = url + '/' + val;
                                break;
                            case 2:
                                bciL.to = url + '/' + val;
                                break;
                            default:
                                bciL.to = url;
                                break;
                        }

                        if (i == parents.length - 1)
                        {
                            bciL.disabled = true;

                        }

                        l.push(bciL);
                    }
                });

                this.breadcrumbs = l;
            },

        },
        data: function ()
        {
            return {
                arrow_back: mdiArrowLeft,
                arrow_forward: mdiArrowRight,
                readableNumber: "",
                loading: true,
                sortBy: [],
                showPendingAction: false,
                showUnconstitutional: false,
                item: {
                    StatuteId: null,
                    Html: null
                },
                hideSessionLawSearch: false,

                breadcrumbs: [],
            };
        },
        mounted: function ()
        {
            this.LoadData();
        },
        watch: {
            Value()
            {
                this.LoadData();
            },

        },
        computed: {
            currentRoute: function ()
            {
                return this.$route.path;
            },
            JsonldBreadCrumbs: function ()
            {
                let CurrentBaseURL = window.location.origin;
                let ListItems = [];

                this.breadcrumbs.forEach((x, i) =>
                {
                    let li = {
                        "@type": "ListItem",
                        "position": i,
                        "name": x.name,
                        "item": CurrentBaseURL + x.to
                    };
                    ListItems.push(li);
                });
                return ListItems;
            },
            Title: function ()
            {
                let title = "Constitutional ";

                if (this.item && this.item.Statute && (typeof this.item.Statute === 'string' || this.item.Statute instanceof String))
                {
                    title = title + this.item.Statute.replace('0N-', 'Article ');
                }

                return title;
            },
            Description: function ()
            {
                if (this.item && this.item.CatchLine)
                {
                    return this.item.Catchline;
                }
                return this.Title;
            }
        },
        metaInfo()
        {
            return {
                title: this.Title,
                script: [
                    {
                        type: 'application/ld+json',
                        json: {
                            '@context': 'http://schema.org',
                            '@type': 'Legislation',
                            "@id": window.location.href,
                            'name': 'Constitution ' + this.readableNumber,
                            'description': this.Description,
                            'legislationType': 'Constitution',
                            'inLanguage': 'English'
                        }
                    }, {
                        type: 'application/ld+json',
                        json: {
                            '@context': 'http://schema.org',
                            '@type': 'BreadcrumbList',
                            "itemListElement": this.JsonldBreadCrumbs
                        }
                    }],
                meta: [
                    { vmid: 'description', name: 'description', content: this.Description },
                    // OpenGraph data (Most widely used)
                    { vmid: 'og:title', property: 'og:title', content: this.Title + ' | South Dakota Legislature' },
                    // The list of types is available here: http://ogp.me/#types
                    { vmid: 'og:type', property: 'og:type', content: 'website' },
                    // Should be the same as your canonical link, see below.
                    { vmid: 'og:url', property: 'og:url', content: window.location.href },
                    // Often the same as your meta description, but not always.
                    { vmid: 'og:description', property: 'og:description', content: this.Description },

                    // Twitter card
                    { vmid: 'twitter:card', name: 'twitter:card', content: 'summary' },
                    { vmid: 'twitter:site', name: 'twitter:site', content: window.location.href },
                    { vmid: 'twitter:title', name: 'twitter:title', content: this.Title + ' | South Dakota Legislature' },
                    { vmid: 'twitter:description', name: 'twitter:description', content: this.Description }
                ],
                link: [
                    { vmid: 'canonical', rel: 'canonical', href: window.location.href.split('?')[0] }
                ]
            };
        }
    };
</script>
<style></style>