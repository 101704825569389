<template>
    <v-row class="ma-0 pa-0">
        <v-col md="2" sm="12" cols="12">
            <Sidebar :SessionId="SessionId"></Sidebar>
        </v-col>
        <v-col md="10" sm="12">
            <div class="headline">
                <span v-if="currentRoute.includes('SDCLIndex')"><span v-if="Session">{{Session.YearString}}</span> SDCL Index</span>
                <span v-else-if="currentRoute.includes('TableOfContents')"><span v-if="Session">{{Session.YearString}}</span> Table Of Contents</span>
                <span v-else-if="currentRoute.includes('TopicalIndex')"><span v-if="Session">{{Session.YearString}}</span> Topical Index</span>
                <span v-else-if="currentRoute.includes('CrossReference')"><span v-if="Session">{{Session.YearString}}</span> Cross Reference</span>
                <span v-else-if="currentRoute.includes('Foreward')"><span v-if="Session">{{Session.YearString}}</span> Forward</span>
                <span v-else-if="currentRoute.includes('Session_Laws/Chapter')"><span v-if="Session">{{Session.YearString}}</span> Session Laws</span>
                <v-progress-linear indeterminate v-if="loading"></v-progress-linear>
                <v-row class="text-center">
                    <v-col cols="12" sm="6" md="5">
                        <v-breadcrumbs divider=">" :items="breadcrumbs" class="py-0" style="font-size:18px;"></v-breadcrumbs>
                    </v-col>
                </v-row>
            </div>
            <v-layout row wrap v-if="item">
                <v-spacer></v-spacer>
                <v-btn v-if="item && item.DocumentId && !loading" style="float:right;" text rel="noopener" :href="$MyLRCUrl.value +'api/Documents/SessionLaws/' + item.DocumentId + '.pdf'">Printer Friendly</v-btn>
            </v-layout>
            <v-container>
                <div v-if="item && item.Html" v-html="item.Html"></div>
            </v-container>
        </v-col>
    </v-row>
</template>

<script>
    const GoogleSearch = () => import("@/Components/GoogleSearch.vue");
    const GoogleSearchStatute = () => import("@/Components/GoogleSearchStatute.vue");
    const Sidebar = () => import("@/Menus/Sidebar.vue");

    export default {
        name: 'SessionLawPart',
        props: ['DocumentTypeId', 'SessionId'],
        components: {
            GoogleSearch,
            GoogleSearchStatute,
            Sidebar
        },
        methods: {
            LoadData()
            {
                this.loading = true;
                this.item.Html = "";

                fetch('/api/Sessions',
                    {
                        method: "GET",
                        credentials: "include",
                        headers: { "Content-Type": "application/json" },
                    })
                    .then(res =>
                    {
                        if (res.ok)
                            return res.json();
                        else
                            return res.text().then(r => Promise.reject(r));
                    })
                    .then(response =>
                    {
                        this.Sessions = EncodeDatetimes(response);
                    })
                    .catch(error =>
                    {
                        if (error == 404)
                            window.location.href = "/404?path=" + encodeURIComponent(window.location.pathname);
                        else if (error != 302)
                        {
                            let e = String(error).substring(0, 100);

                            if ((e || '').indexOf('Failed to fetch') > -1)
                            {
                                window.location.href = "/400?path=" + encodeURIComponent(window.location.pathname) + '&error=' + encodeURIComponent(e);
                            }
                            else
                            {
                                reportError(error);
                                window.location.href = "/500?path=" + encodeURIComponent(window.location.pathname) + '&error=' + encodeURIComponent(e);
                            }
                        }
                    });

                let SessionIdQuery = '&SessionId=' + this.SessionId;

                fetch('/api/Documents/DocumentTypeWithHtml?Type=' + this.DocumentTypeId + SessionIdQuery,
                    {
                        method: "GET",
                        credentials: "include",
                        headers: { "Content-Type": "application/json" },
                    })
                    .then(res =>
                    {
                        if (res.ok)
                        {
                            return res.json();
                        }
                        throw new Error(res.statusText);
                    })
                    .then(response =>
                    {
                        var res = response;
                        this.item = res;

                        let session = this.Sessions.find(x => x.SessionId == this.SessionId);

                        if (session)
                        {
                            this.Session = session;
                        }
                        else
                        {
                            this.Session = { Year: '' };
                        }

                    })
                    .catch(error =>
                    {
                        if (error == 404)
                            window.location.href = "/404?path=" + encodeURIComponent(window.location.pathname);
                        else if (error != 302)
                        {
                            let e = String(error).substring(0, 100);

                            if ((e || '').indexOf('Failed to fetch') > -1)
                            {
                                window.location.href = "/400?path=" + encodeURIComponent(window.location.pathname) + '&error=' + encodeURIComponent(e);
                            }
                            else
                            {
                                reportError(error);
                                window.location.href = "/500?path=" + encodeURIComponent(window.location.pathname) + '&error=' + encodeURIComponent(e);
                            }
                        }
                    })
                    .finally(() =>
                    {
                        this.loading = false;
                    });
            },
        },
        data: function ()
        {
            return {

                readableNumber: "",
                loading: true,

                item: {
                    StatuteId: null,
                    Html: null
                },
                Session: { Year: '' },
                hideSessionLawSearch: false,
                parent: {
                    name: '',
                    url: '',
                },
                bcItem: {
                    href: '',
                    link: true,
                    text: '',
                },
                breadcrumbs: [],
                Sessions: [],
            };
        },
        mounted: function ()
        {
            this.LoadData();
        },
        watch: {
            currentRoute()
            {
                this.LoadData();
            },
            Value: {
                handler()
                {
                    this.LoadData();
                },
            },
        },
        computed: {
            currentRoute: function ()
            {
                return this.$route.path;
            },
            JsonldBreadCrumbs: function ()
            {
                let CurrentBaseURL = window.location.origin;
                let ListItems = [];

                this.breadcrumbs.forEach((x, i) =>
                {
                    let li = {
                        "@type": "ListItem",
                        "position": i,
                        "name": x.text,
                        "item": CurrentBaseURL + x.to
                    };
                    ListItems.push(li);
                });

                return ListItems;
            },
            Title: function ()
            {
                let title = "Session Law Chapter ";

                if (this.Session && this.Session.Year)
                {
                    title = this.Session.Year + ' ' + title;
                }
                else
                {
                    title = "Session Laws" + ' ' + title;
                }

                if (this.item && this.item.Chapter)
                {
                    title = title + ' ' + this.item.Chapter;
                }

                return title;
            },
            Description: function ()
            {
                if (this.item && this.item.CatchLine)
                {
                    return this.item.Catchline;
                }

                return this.Title;
            }
        },
        metaInfo()
        {
            return {
                title: this.Title,
                script: [
                    {
                        type: 'application/ld+json',
                        json: {
                            '@context': 'http://schema.org',
                            '@type': 'Legislation',
                            "@id": window.location.href,
                            'name': "Session Law " + this.readableNumber,
                            'description': this.Description,
                            'legislationType': "Session Law",
                            'inLanguage': 'English'
                        }
                    }, {
                        type: 'application/ld+json',
                        json: {
                            '@context': 'http://schema.org',
                            '@type': 'BreadcrumbList',
                            "itemListElement": this.JsonldBreadCrumbs
                        }
                    }],
                meta: [
                    { vmid: 'description', name: 'description', content: this.Description },
                    // OpenGraph data (Most widely used)
                    { vmid: 'og:title', property: 'og:title', content: this.Title + ' | South Dakota Legislature' },
                    // The list of types is available here: http://ogp.me/#types
                    { vmid: 'og:type', property: 'og:type', content: 'website' },
                    // Should be the same as your canonical link, see below.
                    { vmid: 'og:url', property: 'og:url', content: window.location.href },
                    // Often the same as your meta description, but not always.
                    { vmid: 'og:description', property: 'og:description', content: this.Description },

                    // Twitter card
                    { vmid: 'twitter:card', name: 'twitter:card', content: 'summary' },
                    { vmid: 'twitter:site', name: 'twitter:site', content: window.location.href },
                    { vmid: 'twitter:title', name: 'twitter:title', content: this.Title + ' | South Dakota Legislature' },
                    { vmid: 'twitter:description', name: 'twitter:description', content: this.Description }
                ],
                link: [
                    { vmid: 'canonical', rel: 'canonical', href: window.location.href.split('?')[0] }
                ]
            };
        }
    };
</script>
<style></style>