<template>
    <v-row class="ma-0 pa-0">
        <v-col md="2" sm="12" cols="12">
            <Sidebar></Sidebar>
        </v-col>
        <v-col md="10" sm="12">
            <div class="headline">
                Administrative Rules
                <v-progress-linear indeterminate v-if="loading"></v-progress-linear>
                <v-row class="text-center">
                    <v-col cols="12" sm="6" md="5">
                        <v-breadcrumbs divider=">" :items="breadcrumbs" class="py-0" style="font-size:18px;"></v-breadcrumbs>
                    </v-col>
                    <v-col cols="12" sm="6" md="7" v-if="!loading && item">
                        <v-btn class="ma-2" color="primary" v-if="item.Previous" :to="'/Rules/Administrative/' + item.Previous">
                            <v-icon>{{arrow_back}}</v-icon> Previous
                        </v-btn>
                        <v-btn color="primary" v-if="item.Next" :to="'/Rules/Administrative/' + item.Next">
                            Next <v-icon>{{arrow_forward}}</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-container>
                    <v-layout row wrap>
                        <v-flex xs12>
                            <GoogleSearchStatute></GoogleSearchStatute>
                        </v-flex>
                    </v-layout>
                </v-container>
            </div>
            <v-divider style="height: 10px; max-height: 10px"></v-divider>
            <v-layout row wrap v-if="Value != 'null' && item">
                <v-btn v-if="item && item.RuleId" text rel="noopener" :href="'/api/Rules/' + item.RuleId + '.docx'">
                    Download {{item.RuleNumber}} in Microsoft Word Format
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn v-if="item && !loading" style="float:right;" text rel="noopener" :href="'/api/Rules/Rule/' + item.RuleNumber + '.html?all=true'">Printer Friendly</v-btn>
            </v-layout>
            <v-container>
                <p v-if="showPendingAction" style="color: red; font-size: larger;" class="headline">
                    Pending Court Action
                </p>
                <p v-if="showUnconstitutional" style="color: red; font-size: larger;" class="headline">
                    Article XXX was held unconstitutional by <a href="https://ujs.sd.gov/uploads/sc/opinions/2954698e671a.pdf" style="color: inherit; text-decoration:underline;">Thom v. Barnett, 2021 S.D. 65 (2021).</a>
                </p>
                <div v-if="Value && Value != 'null' && item && item.Html" v-html="item.Html"></div>
                <div v-else>
                    <v-alert v-if="!loading" dense
                             text
                             type="info">Not yet available.</v-alert>
                </div>
            </v-container>
            <v-container v-if="item && item.Archived">
                Online Archived History:

                <v-row v-for="r in item.Archived" :key="r.ArchivedRuleId" no-gutters>
                    <v-col md="6" cols="12">
                        <v-btn block rel="noopener" :href="'/api/Rules/Archived/' + r.ArchivedRuleId + '.pdf'" outlined>{{r.Effective | FormatDates}}</v-btn>
                    </v-col>
                </v-row>
                <v-alert v-if="!loading && item && item.Archived && item.Archived.length < 1" type="info" dense>
                    There are currently no online archives for this rule.
                </v-alert>
            </v-container>
        </v-col>
    </v-row>
</template>

<script>
    const GoogleSearch = () => import("@/Components/GoogleSearch.vue");
    const GoogleSearchStatute = () => import("@/Components/GoogleSearchStatute.vue");
    const Sidebar = () => import("@/Menus/Sidebar.vue");
    import { mdiArrowLeft } from '@mdi/js';
    import { mdiArrowRight } from '@mdi/js';

    export default {
        name: 'AdminRule',
        props: ['Value'],
        components: {
            GoogleSearch,
            GoogleSearchStatute,
            Sidebar
        },
        methods: {
            LoadData()
            {
                this.loading = true;

                this.item = {
                    Html: "",
                    StatuteId: null
                };

                if (this.Value != "null")
                {
                    fetch('/api/Rules/' + encodeURIComponent(this.Value),
                        {
                            method: "GET",
                            credentials: "include",
                            headers: { "Content-Type": "application/json" },
                        })
                        .then(res =>
                        {
                            if (res.redirected && res.url)
                            {
                                this.$router.replace(res.url.replace(location.origin, ''));
                                return Promise.reject(302);
                            }
                            else if (res.ok)
                                return res.json();
                            else if (res.status === 404)
                                return Promise.reject(404);
                            else
                                return res.text().then(r => Promise.reject(r));
                        })
                        .then(response =>
                        {
                            this.item = response;
                            this.createBreadcrumbs("Administrative Rules", "/Rules/Administrative", this.item.RuleNumber, this.item.Parents);
                        })
                        .catch(error =>
                        {
                            if (error == 404)
                                window.location.href = "/404?path=" + encodeURIComponent(window.location.pathname);
                            else if (error != 302)
                            {
                                let e = String(error).substring(0, 100);

                                if ((e || '').indexOf('Failed to fetch') > -1)
                                {
                                    window.location.href = "/400?path=" + encodeURIComponent(window.location.pathname) + '&error=' + encodeURIComponent(e);
                                }
                                else
                                {
                                    reportError(error);
                                    window.location.href = "/500?path=" + encodeURIComponent(window.location.pathname) + '&error=' + encodeURIComponent(e);
                                }
                            }
                        })
                        .finally(() =>
                        {
                            this.loading = false;
                        });
                }
            },
            createBreadcrumbs(route, url, child, parents)
            {
                let l = [
                    {
                        to: '/',
                        link: true,
                        text: 'Home',
                        name: 'Home'
                    },
                    {
                        to: url,
                        link: true,
                        text: route,
                        exact: true,
                        name: route
                    }];

                var split = [];

                parents.forEach((x, i) =>
                {
                    var bciL = {
                        to: url + '/' + x.RuleNumber,
                        link: true,
                        text: x.DisplayRuleNumber,
                        exact: true,
                        name: x.RuleNumber
                    };

                    if (i == parents.length - 1)
                        bciL.disabled = true;

                    l.push(bciL);
                });

                this.breadcrumbs = l;

            },

        },
        data: function ()
        {
            return {
                arrow_back: mdiArrowLeft,
                arrow_forward: mdiArrowRight,
                loading: true,
                sortBy: [],
                showPendingAction: false,
                showUnconstitutional: false,
                item: {
                    StatuteId: null,
                    Html: null
                },
                parent: {
                    name: '',
                    url: '',
                },
                bcItem: {
                    href: '',
                    link: true,
                    text: '',
                },
                breadcrumbs: [],
            };
        },
        mounted: function ()
        {
            this.LoadData();
        },
        watch: {
            Value()
            {
                this.LoadData();
            },
        },
        computed: {
            currentRoute: function ()
            {
                return this.$route.path;
            },
            JsonldBreadCrumbs: function ()
            {
                let CurrentBaseURL = window.location.origin;
                let ListItems = [];
                this.breadcrumbs.forEach((x, i) =>
                {
                    let li = {
                        "@type": "ListItem",
                        "position": i,
                        "name": x.name,
                        "item": CurrentBaseURL + x.to
                    };
                    ListItems.push(li);
                });
                return ListItems;
            },
            Title: function ()
            {
                let title = '';
                if (this.currentRoute.includes('Constitution'))
                {
                    title = "Constitutional ";
                    if (this.item && this.item.Statute && (typeof this.item.Statute === 'string' || this.item.Statute instanceof String))
                    {
                        title = title + this.item.Statute.replace('0N-', 'Article ');
                    }
                }
                else if (this.currentRoute.includes('Administrative'))
                {
                    title = "Administrative Rule ";
                    if (this.item && this.item.RuleNumber)
                    {
                        title = title + this.item.RuleNumber;
                    }
                }
                else if (this.currentRoute.includes('Session_Laws'))
                {
                    title = "Session Law Chapter ";
                    if (this.Session && this.Session.Year)
                    {
                        title = this.Session.Year + ' ' + title;
                    }
                    else
                    {
                        title = "Session Laws" + ' ' + title;
                    }
                    if (this.item && this.item.Chapter)
                    {
                        title = title + ' ' + this.item.Chapter;
                    }
                }
                else if (this.currentRoute.includes('Codified_Laws') || this.currentRoute.includes('Statutes'))
                {
                    title = "Codified Law ";
                    if (this.item && this.item.Statute)
                    {
                        title = title + this.item.Statute;
                    }
                }
                return title;
            },
            Description: function ()
            {
                if (this.item && this.item.CatchLine)
                {
                    return this.item.Catchline;
                }
                return this.Title;
            }
        },
        metaInfo()
        {
            return {
                title: this.Title,
                script: [
                    {
                        type: 'application/ld+json',
                        json: {
                            '@context': 'http://schema.org',
                            '@type': 'Legislation',
                            "@id": window.location.href,
                            'name': 'Administrative Rule ' + ((this.item || {}).RuleNumber || ""),
                            'description': this.Description,
                            'legislationType': "Administrative Rule",
                            'inLanguage': 'English'
                        }
                    }, {
                        type: 'application/ld+json',
                        json: {
                            '@context': 'http://schema.org',
                            '@type': 'BreadcrumbList',
                            "itemListElement": this.JsonldBreadCrumbs
                        }
                    }],
                meta: [
                    { vmid: 'description', name: 'description', content: this.Description },
                    // OpenGraph data (Most widely used)
                    { vmid: 'og:title', property: 'og:title', content: this.Title + ' | South Dakota Legislature' },
                    // The list of types is available here: http://ogp.me/#types
                    { vmid: 'og:type', property: 'og:type', content: 'website' },
                    // Should be the same as your canonical link, see below.
                    { vmid: 'og:url', property: 'og:url', content: window.location.href },
                    // Often the same as your meta description, but not always.
                    { vmid: 'og:description', property: 'og:description', content: this.Description },

                    // Twitter card
                    { vmid: 'twitter:card', name: 'twitter:card', content: 'summary' },
                    { vmid: 'twitter:site', name: 'twitter:site', content: window.location.href },
                    { vmid: 'twitter:title', name: 'twitter:title', content: this.Title + ' | South Dakota Legislature' },
                    { vmid: 'twitter:description', name: 'twitter:description', content: this.Description }
                ],
                link: [
                    { vmid: 'canonical', rel: 'canonical', href: window.location.href.split('?')[0] }
                ]
            };
        }
    };
</script>
<style></style>